<template>
  <v-container class="mx-auto mx-lg-0 pt-0">
    <div class="rs-lead-sheet">
      <div v-if="loading">
        <v-container>
          <div class="h-100 d-flex align-center justify-center">
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </div>
        </v-container>
      </div>
      <div v-if="!loading && reportInput && prospect">
        <v-container class="pt-0">
          <main>
            <v-row>
              <v-col>
                <v-card
                  ref="headerRow"
                  outlined
                  elevation="12"
                  class="rs-lead-sheet__bg d-flex justify-space-between"
                  dark
                  min-height="250"
                >
                  <v-row>
                    <v-col
                      class="
                        col-12 col-lg-4
                        px-8
                        flex-column
                        align-start align-self-center
                      "
                      style="white-space: nowrap"
                    >
                      <v-list color="transparent">
                        <v-list-item class="mb-2">
                          <h4 class="mr-2">Created:</h4>
                          {{ handleFormatDate(prospect.createdAt) }}
                        </v-list-item>
                        <!-- <v-list-item class="mb-2">
                          <div>
                            <h4>Address:</h4>
                            <template v-if="prospect.address">
                              <div>
                                {{
                                  prospect.address.streetAddress1
                                    ? prospect.address.streetAddress1
                                    : "--"
                                }}
                              </div>
                              <div>
                                {{
                                  prospect.address.streetAddress2
                                    ? prospect.address.streetAddress2
                                    : null
                                }}
                              </div>
                              <div>
                                {{
                                  prospect.address.city ? prospect.address.city : "--"
                                }},
                                {{
                                  prospect.address.state ? prospect.address.state : "--"
                                }}
                                {{
                                  prospect.address.postalCode
                                    ? prospect.address.postalCode
                                    : "--"
                                }}
                              </div>
                            </template>
                            <div v-else>No Address</div>
                          </div>
                        </v-list-item> -->
                      </v-list>
                    </v-col>

                    <v-col
                      class="
                        col-12 col-lg-4
                        pa-4
                        flex-grow-1
                        d-flex
                        flex-column
                        justify-center
                        align-center
                      "
                    >
                      <div style="width: 100px" class="mb-4">
                        <GenderAgeAvatarStandalone
                          v-if="hasSurveyReport"
                          :age="reportInput.financial_profile_info.current_age"
                          :gender="reportInput.gender"
                        />
                        <v-avatar v-else color="primary" :size="100">
                          <v-icon dark x-large>mdi-account-circle </v-icon>
                        </v-avatar>
                      </div>
                      <div
                        class="mb-1"
                        :class="{
                          'text-h6 font-weight-bold':
                            prospect.firstName || prospect.lastName,
                        }"
                      >
                        <template
                          v-if="!prospect.firstName && !prospect.lastName"
                        >
                          No Name
                        </template>
                        <template v-else>
                          {{
                            prospect.firstName
                              ? prospect.firstName
                              : "No First Name"
                          }}
                          {{
                            prospect.lastName
                              ? prospect.lastName
                              : "No Last Name"
                          }}
                        </template>
                      </div>
                      <!-- <div class="mb-1">
                        {{ prospect.email ? prospect.email : "No Email Address" }}
                      </div>
                      <div>
                        {{ prospect.phone ? prospect.phone : "No Phone Number" }}
                      </div> -->
                    </v-col>

                    <v-col
                      class="
                        col-12 col-lg-4
                        pa-4
                        d-flex
                        flex-column
                        justify-center
                        align-center
                      "
                    >
                      <v-list color="transparent">
                        <v-list-item
                          v-if="hasSurveyReport"
                          class="mb-2 justify-end"
                        >
                          <v-btn
                            text
                            :href="getReportLink('full')"
                            target="_blank"
                          >
                            View Full Score Report
                            <v-icon small class="ml-2"
                              >mdi-arrow-right</v-icon
                            ></v-btn
                          >
                        </v-list-item>
                        <v-list-item class="mb-2 justify-end">
                          <DownloadPrintPdf
                            :prospectId="prospect.id"
                            pdfType="print"
                            buttonText="Print Score Report"
                            :iconOnly="false"
                            iconSize="medium"
                            appendIcon="mdi-arrow-right"
                          />
                          <!-- <v-btn text @click="handlePdf('download')">
                            Print Score Report
                            <v-icon small class="ml-2">mdi-download</v-icon></v-btn
                          > -->
                        </v-list-item>
                        <!-- <v-list-item class="mb-2 justify-end">
                          <v-btn text @click="showEditDialog = true">
                            Edit Client Details
                            <v-icon small class="ml-2">mdi-arrow-right</v-icon></v-btn
                          >
                        </v-list-item> -->
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <section v-if="!hasSurveyReport" class="text-center">
                  <v-card>
                    <v-card-text>
                      <h3 class="mb-4">
                        Client has not completed the Risk Score Assessment.
                      </h3>
                      <p><a href="#">Conduct assessment now?</a></p>
                    </v-card-text></v-card
                  >
                </section>
                <template v-else>
                  <section id="scroll_score_results" class="mb-8">
                    <v-card outlined>
                      <div class="mb-2 px-6 pt-4 pb-0 d-flex">
                        <h2 class="text-h5 font-weight-bold mb-0">
                          {{
                            prospect.firstName ? prospect.firstName + "'s" : ""
                          }}
                          Risk Score Results
                        </h2>
                      </div>
                      <v-card-text>
                        <v-row>
                          <v-col class="pa-4">
                            <div style="max-width: 400px" class="mx-auto">
                              <ReportCompareAnimationSideBySide
                                :prospectScore="reportInput.score"
                                :averageScore="
                                  reportInput.average_score_for_age
                                "
                                colorScheme="light"
                              />
                            </div>
                          </v-col>
                          <v-col class="col-12 col-sm-6 col-md-8">
                            <v-row>
                              <v-col>
                                <v-list color="transparent">
                                  <v-list-item>
                                    <v-list-item-title>
                                      Age:
                                      <strong>{{
                                        reportInput.financial_profile_info
                                          .current_age
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-divider></v-divider>
                                  <v-list-item>
                                    <v-list-item-title>
                                      Age Generation:
                                      <strong>{{
                                        reportInput.generation
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-divider></v-divider>
                                  <v-list-item>
                                    <v-list-item-title>
                                      Lifetime Benefit Cut:
                                      <strong>{{
                                        reportInput.social_security_cut_info
                                          .lifetime_benefit_cut
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                              <v-col class="px-8">
                                <v-list color="transparent">
                                  <v-list-item>
                                    <v-list-item-title>
                                      Estimated Benefits at Risk:
                                      <strong>{{
                                        reportInput.social_security_cut_info
                                          .promised_lifetime_total_benefits_post_reduction
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-divider></v-divider>
                                  <v-list-item>
                                    <v-list-item-title>
                                      Financial Profile:
                                      <strong class="text-capitalize">{{
                                        reportInput.financial_profile_info
                                          .profile
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-divider></v-divider>
                                  <v-list-item>
                                    <v-list-item-title>
                                      Likelihood to Receive Benefit Cut:
                                      <strong class="text-capitalize">{{
                                        reportInput.financial_profile_info
                                          .profile_likelihood
                                      }}</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <div class="d-flex justify-center">
                          <v-btn
                            small
                            text
                            @click.prevent="
                              scrollToAnchor('#scroll_survey_answers')
                            "
                            >Jump to Survey Answers
                            <v-icon small class="ml-4"
                              >mdi-arrow-down</v-icon
                            ></v-btn
                          >
                        </div>
                      </v-card-text>
                    </v-card>
                  </section>

                  <!-- Grid of Prospect Survey Answers -->
                  <section id="scroll_survey_answers" class="mb-8">
                    <v-card outlined>
                      <div class="mb-4 px-6 pt-4 pb-0 d-flex">
                        <h2 class="text-h5 font-weight-bold mb-0">
                          {{
                            prospect.firstName ? prospect.firstName + "'s" : ""
                          }}
                          Risk Score Survey Answers
                        </h2>
                      </div>
                      <v-card-text>
                        <v-row>
                          <v-col
                            v-for="a in prospect.scoreSurveyAnswers"
                            :key="a.id"
                            class="col-4 rs-lead-sheet__answer"
                          >
                            <!-- <v-card outlined class="mb-2 mr-4 h-100"> -->
                            <div
                              class="
                                d-flex
                                flex-column
                                justify-center
                                align-center
                              "
                            >
                              <v-img
                                :src="handleGetSurveyIcon(a.param)"
                                max-width="56"
                                max-height="56"
                              />
                              <div class="mt-2 text-center">
                                <p
                                  class="mb-0 text-caption font-weight-bold"
                                  style="line-height: 1.3"
                                >
                                  {{
                                    a.param.replaceAll("_", "\n").toUpperCase()
                                  }}
                                </p>

                                <p
                                  class="
                                    mb-0
                                    text-center text-body-1
                                    font-weight-bold
                                    primary--text
                                    py-4
                                    flex-grow-1
                                  "
                                >
                                  {{ a.value }}
                                </p>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </section>
                </template>
              </v-col>
            </v-row>
            <Footer />
          </main>
        </v-container>
      </div>
    </div>

    <v-dialog
      v-if="prospect"
      v-model="showEditDialog"
      max-width="800"
      width="75%"
    >
      <v-card>
        <v-card-title class="d-flex mb-4">
          <h3>Edit Client</h3>
          <v-btn icon @click="showEditDialog = false" class="ml-auto">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <div v-if="updating">
            <v-container>
              <div class="h-100 d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  size="70"
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-container>
          </div>
          <v-form
            v-else
            @submit.prevent="handleUpdateProspect"
            v-model="valid"
            class="mt-4"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="prospect.firstName"
                  :rules="firstNameRules"
                  label="First Name"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="text"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="prospect.lastName"
                  :rules="lastNameRules"
                  label="Last Name"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="text"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="prospect.email"
                  :rules="emailRules"
                  label="Email Address"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  required
                  type="email"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="prospect.phone"
                  v-maska="'###-###-####'"
                  label="Phone Number"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  type="tel"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="prospect.address.streetAddress1"
                  label="Street Address 1"
                  persistent-hint
                  outlined
                  clearable
                  type="text"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="prospect.address.streetAddress2"
                  label="Street Address 2"
                  outlined
                  clearable
                  type="text"
                  background-color="white"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="prospect.address.city"
                  label="City"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  type="text"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field
              ></v-col>
              <v-col>
                <v-select
                  v-model="prospect.address.state"
                  :items="states"
                  item-text="name"
                  item-value="abbreviation"
                  :menu-props="{ maxHeight: '400' }"
                  label="State"
                  hint="REQUIRED"
                  persistent-hint
                  clearable
                  outlined
                  class="rs-text-field--required"
                  background-color="white"
                ></v-select
              ></v-col>
              <v-col>
                <v-text-field
                  v-model="prospect.address.postalCode"
                  label="Postal Code"
                  hint="REQUIRED"
                  persistent-hint
                  outlined
                  clearable
                  type="text"
                  class="rs-text-field--required"
                  background-color="white"
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex align-center">
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="showEditDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!valid"
                  class="ml-4 font-weight-bold"
                  >Update Client</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapSurveyIcon } from "@/services/surveyIconMapping";
import Footer from "@/components/Footer";
import GenderAgeAvatarStandalone from "@/components/GenderAgeAvatarStandalone";
import ReportCompareAnimationSideBySide from "../components/ReportCompareAnimationSideBySide";
import DownloadPrintPdf from "@/components/DownloadPrintPdf.vue";
import states from "@/assets/states.json";

const initialProspect = function () {
  return {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: {
      streetAddress1: null,
      streetAddress2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  };
};

export default {
  components: {
    DownloadPrintPdf,
    Footer,
    GenderAgeAvatarStandalone,
    ReportCompareAnimationSideBySide,
  },
  props: {
    prospectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      prospect: null,
      report: null,
      reportInput: null,
      loading: false,
      hasSurveyReport: null,
      showEditDialog: false,
      updating: false,
      valid: false,
      states: states,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: null,
      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) =>
          (v && v.length >= 2) || "First name must be at least two letters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => (v && v.length >= 2) || "Last name must be at least two letters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      zipCodeRules: [
        (v) =>
          !v ||
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid postal code",
      ],
    };
  },

  mounted() {
    // const scoreSurveyCompleteParam = this.$route.query.surveyComplete;
    this.prospect = new initialProspect();
    this.fetchProspect(this.prospectId);
  },
  methods: {
    scrollToAnchor(to) {
      document.querySelector(to).scrollIntoView({
        behavior: "smooth",
      });
    },

    getReportLink(reportType) {
      let path = "your-score-report";
      if (reportType === "abbreviated") {
        path = "score-report";
      }
      return `${window.location.origin}/${path}/${this.prospect.id}`;
    },
    handleGetSurveyIcon(param) {
      const iconFileName = mapSurveyIcon(param);
      if (iconFileName) {
        return require("@/assets/surveyIcons/" + iconFileName);
      }
    },
    handleFormatDate(date) {
      let newDate = new Date(date);

      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      };
      let formattedDate = newDate.toLocaleDateString("en-US", options);

      return formattedDate;
    },

    fetchProspect(prospectId) {
      // Shouldn't get here, the table search should filter to prospects with completed surveys
      this.loading = true;

      fetch(process.env.VUE_APP_SCORE_API + "prospect/" + prospectId, {
        method: "GET",
        // headers: {
        //   // Authorization: `Bearer ${jwt}`,
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(this.formData),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong finding client", response);
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("data success client", data);

            this.prospect = {
              ...this.prospect,
              ...data.prospect,
            };
            console.log("PROSPECT", this.prospect);

            if (this.prospect.scoreSurveyComplete) {
              this.fetchReport(this.prospectId);
            }

            // if (this.prospect.scoreSurveyAnswers) {
            //   const answers = this.prospect.scoreSurveyAnswers;

            //   const avatar = prospectAvatarComponent(answers);

            //   if (avatar) {
            //     this.prospect.avatar = avatar;
            //   }

            //   console.log("this. prop aava", this.prospect.avatar);
            // }
          } else {
            console.log("Error data:", data);

            throw new Error(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.message = "Sorry, the client could not be found.";
          this.loading = false;
        });

      // try {
      //   console.log("Attempting to get client", prospectId);

      //   // const response = await API.graphql(
      //   //   graphqlOperation(getProspect, { id: prospectId })
      //   // );

      //   // this.prospect = response.data.getProspect;

      //   // if (!this.prospect) {
      //   //   this.message = "Sorry, the client could not be found.";
      //   //   this.loading = false;
      //   //   return;
      //   // }
      // } catch (error) {
      //   console.log("Error getting client...", error);
      // } finally {
      //   this.loading = false;
      // }
    },
    fetchReport(prospectId) {
      // Fetch score by submitting scoring params
      this.loading = true;

      fetch(process.env.VUE_APP_SCORE_API + "score_report_v4", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prospect_id: prospectId,
          template_id: null,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong fetching report", response);
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("data report", data);
            if (!data.input) {
              this.message =
                "Sorry, we were unable to locate your report. Please refresh to try again or contact your advisor for assistance.";
            } else {
              this.report = data.report;
              this.reportInput = data.input;
              console.log("REPORT INPUT", this.reportInput);
              this.hasSurveyReport = true;
              this.loading = false;
            }
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (!this.report) {
            this.message = error.message;
          }
        });
    },
    handleUpdateProspect() {
      this.updating = true;

      fetch(process.env.VUE_APP_SCORE_API + "prospect", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.prospect),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong updating client", response);
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("update success", data);

            this.prospect = data.prospect;

            setTimeout(() => {
              this.showEditDialog = false;
              this.updating = false;

              this.snackBarColor = "success";
              this.snackBarText = "Client Updated!";
              this.showSnackBar = true;
            }, 500);
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.message = `<h3>Error, ${error}!</h3>>`;
          this.snackBarColor = "error";
          this.snackBarText = "Error: Could not update client.";
          this.showSnackBar = true;
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section[id^="scroll"] {
  scroll-margin-top: 80px;
}
.rs-lead-sheet {
  &__product-header {
    position: relative;

    &:before {
      content: "";
      background: #4188ec;
      position: absolute;
      left: calc(100% - 1px);
      top: 0;
      height: 100%;
      width: 1rem;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
    }
  }

  &__recommendation {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  &__bg {
    position: relative;
    background: linear-gradient(#18466f, #316eae);
    &:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        280deg,
        hsla(0, 0%, 100%, 0.07058823529411765) 40%,
        rgba(35, 77, 122, 0.00392156862745098) 0
      );
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: 100%;
    }
  }

  &.theme--light.v-application {
    background-color: #f0f2f5;
  }

  &__answer {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    // every third
    &:nth-child(3n + 3) {
      border-right: none;
    }

    // last three
    &:nth-last-child(-n + 3) {
      border-bottom: none;
    }
  }
  /*
  &__nav {
    .v-list-item {
      &__icon {
        display: none;
      }

      &--active {
        .v-list-item__icon {
          display: block;
        }
      }
    }
  } */

  .theme--dark {
    .v-list-item--active,
    .v-list-item--active:hover {
      color: var(--v-primary-base);

      .v-list-item__title {
        font-weight: bold;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
